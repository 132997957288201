import * as React from "react";
import styled from "styled-components";
import Groups from "../components/Groups";

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  > * {
    grid-column: 2;
  }
`;

// markup
const IndexPage = () => {
  return (
    <Main>
      <Groups />
    </Main>
  );
};

export default IndexPage;
