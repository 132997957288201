import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PickemLayout, {
  Card,
  Picks,
  GroupTitle,
  LoadPickemsBar,
} from "./styles";
import groupsExportSourceData from "../assets/data";

const grid = 1;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#505050" : "#303030",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#505050" : "#505050",
  padding: grid,
  margin: 0,
  width: 400,
});

export default function Groups() {
  const [teams, setTeams] = useState(groupsExportSourceData);
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const groups = ["Group A", "Group B", "Group C"];

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(teams[sInd], source.index, destination.index);
      const newState = [...teams];
      newState[sInd] = items;
      setTeams(newState);
    }
  }

  const loadPredictions = async (e, action) => {
    e.preventDefault();
    const body = {
      predictions: teams,
      action: action,
      name: name,
      pass: pass,
      stage: "groups",
    };
    const res = await fetch(`/.netlify/functions/predictions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const text = await res.json();

    if (text) {
      setTeams(JSON.parse(text.predictions));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <LoadPickemsBar>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          id="pass"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
        <button type="button" onClick={(e) => loadPredictions(e, "update")}>
          Submit
        </button>
        <button type="button" onClick={(e) => loadPredictions(e, "load")}>
          Load
        </button>
      </LoadPickemsBar>
      <PickemLayout>
        {teams.map((group, ind) => {
          return (
            <Picks key={ind}>
              <GroupTitle>{groups[ind]}</GroupTitle>
              <Droppable droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="characters"
                  >
                    {group.map((team, index) => {
                      return (
                        <Draggable
                          key={team.id}
                          draggableId={team.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>{1 + index}</div>
                              <div className="characters-thumb">
                                <img
                                  src={team.thumb}
                                  alt={`${team.name} Thumb`}
                                />
                              </div>
                              <p>{team.name}</p>
                            </Card>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </Picks>
          );
        })}
      </PickemLayout>
    </DragDropContext>
  );
}
